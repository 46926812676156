import Vue from "vue";
import moment from "moment";

import template from "./templateOverview.html";
import SortToolbar from "@/components/SortToolbar/sortToolbar";
import "./templateOverview.scss";
import EditDialog from "./EditDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

import { namespaceHelpers } from "@/store/modules/templates";
import { namespaceHelpers as mediaPoolHelpers } from "@/store/modules/mediaPool";
import { namespaceHelpers as outputChannelHelpers } from "@/store/modules/outputChannels";

export default Vue.extend({
  template,

  props: ["isLoading"],

  async activated() {
    this.load();
    this.loadMedia();
    this.loadOutputChannels();
  },

  components: {
    SortToolbar,
    EditDialog,
    DeleteDialog,
  },

  data() {
    return {
      sort: "uploaded",
      searchText: "",
      currentTemplate: null,
      name: "",
      dialog: false,
    };
  },

  methods: {
    ...namespaceHelpers.mapActions({
      load: "load",
      delete: "delete",
    }),
    ...mediaPoolHelpers.mapActions({
      loadMedia: "load",
    }),
    ...outputChannelHelpers.mapActions({
      loadOutputChannels: "load",
    }),
    onEdit(template) {
      this.dialog = true;
      this.currentTemplate = template;
      this.$refs.editDialog.show();
    },
    showDeleteDialog(url) {
      this.$root.$emit("showDeleteDialog", {
        dialogId: this._uid,
        currentId: url,
        visible: true,
      });
    },

    deleteTemplate(id) {
      this.delete({ id });
    },

    previewImage(slide) {
      const image = this.previewImageForSlideId(slide.id);
      if (image != null) {
        return image.url;
      }
    },
  },

  computed: {
    ...namespaceHelpers.mapState({
      data: (state) => state.data,
    }),

    ...mediaPoolHelpers.mapGetters({ previewImageForSlideId: "previewImageForSlideId" }),

    filteredTemplates() {
      if (!this.data) {
        return [];
      }

      const ret = this.data
        .filter(
          (item) => item.name == null || item.name.toLowerCase().indexOf((this.searchText || "").toLowerCase()) > -1
        )
        .sort((item1, item2) =>
          this.sort === "uploaded"
            ? moment(item2.lastModified).diff(moment(item1.lastModified))
            : item1.name.localeCompare(item2.name)
        );

      return ret;
    },
  },
});
