import Vue from "vue";
import types from "./types";
import { generateId } from "@/util/helpers";

import { PLACEHOLDER_IMAGE_SIZE } from "@/config/constants.js";

export const mutations = {
  [types.mutations.REQUEST](state) {
    state.isLoading = true;
    state.isLoaded = false;
    state.error = {};
  },

  [types.mutations.FAILURE](state, payload) {
    state.isLoading = false;
    state.isLoaded = true;
    state.error = payload.response.data;
  },

  [types.mutations.SUCCESS](state, payload) {
    state.isLoading = false;
    state.isLoaded = true;
    state.error = {};
    state.data = payload.data;
  },

  [types.mutations.SUCCESS_EDIT](state, payload) {
    state.isLoading = false;
    state.isLoaded = false;
    state.error = {};
    const index = state.data.findIndex((elem) => elem.id == payload.data.id);
    Vue.set(state.data, index, payload.data);
  },

  [types.mutations.SUCCESS_ADD](state, payload) {
    state.isLoading = false;
    state.isLoaded = false;
    state.error = {};
    Vue.set(state.data, state.data.length, payload.data);
  },

  [types.mutations.SUCCESS_DELETE](state, payload) {
    state.isLoading = false;
    state.isLoaded = false;
    state.error = {};
    const index = state.data.findIndex((elem) => elem.id == payload.id);
    Vue.delete(state.data, index);
  },

  [types.mutations.SUCCESS_EDIT_SLIDE](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    if (payload.name) {
      template.slides[slideIndex].name = payload.name;
    }
    if (payload.backgroundColor) {
      template.slides[slideIndex].backgroundColor = payload.backgroundColor;
    }
    if (payload.width) {
      template.slides[slideIndex].width = payload.width;
    }
    if (payload.height) {
      template.slides[slideIndex].height = payload.height;
    }
    if (payload.showGrid || payload.showGrid === false) {
      template.slides[slideIndex].showGrid = payload.showGrid;
    }
    if (payload.gridWidth) {
      template.slides[slideIndex].gridWidth = payload.gridWidth;
    }
    if (payload.gridHeight) {
      template.slides[slideIndex].gridHeight = payload.gridHeight;
    }
    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_ADD_SLIDE](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];

    template.slides.push({
      id: generateId(),
      duration: 100,
      name: `Slide ${template.slides.length + 1}`,
      // TODO set/get last set width/height
      width: 1920,
      height: 1080,
      color: "#FFFFFFFF",
      components: [],
    });

    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_DUPLICATE_SLIDE](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];

    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    const slideCopy = {
      ...template.slides[slideIndex],
      id: generateId(), // !!!! this needs to be last as it should override the copied value
    };
    slideCopy.components = [];
    const componentsToCopy = template.slides[slideIndex].components;
    if (componentsToCopy) {
      componentsToCopy.forEach((component) => {
        slideCopy.components.push({
          ...component,
          id: generateId(),
        });
      });
    }

    template.slides.splice(slideIndex + 1, 0, slideCopy);
    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_DELETE_SLIDE](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    template.slides.splice(slideIndex, 1);
    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_ORDER_SLIDES](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];
    template.slides = payload.slides;
    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_ADD_COMPONENT](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];
    if (!template.slides) {
      template.slides = [];
    }
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    if (!template.slides[slideIndex].components) {
      template.slides[slideIndex].components = [];
    }

    const metadata = payload.metadata;
    let defaultSize = null;
    if (metadata && metadata.width && metadata.height) {
      defaultSize = { width: metadata.width, height: metadata.height };
    } else if (payload.type == "image") {
      defaultSize = PLACEHOLDER_IMAGE_SIZE;
    }
    if (defaultSize) {
      const defaultRatio = defaultSize.width / defaultSize.height;
      const templateWidth = template.slides[slideIndex].width;
      const templateHeight = template.slides[slideIndex].height;
      // for ratio calculation use absolute pixel values --> percentages relate to absolute width/height
      const componentRatio = (payload.component.width * templateWidth) / (payload.component.height * templateHeight);
      if (defaultRatio.toFixed(2) !== componentRatio.toFixed(2)) {
        payload.component.height =
          (((payload.component.width * defaultSize.height) / defaultSize.width) * templateWidth) / templateHeight;
      }
    }

    template.slides[slideIndex].components.push(payload.component);
    Vue.set(state.data, templateIndex, template);
  },

  async [types.mutations.SUCCESS_DELETE_COMPONENT](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    const componentIndex = template.slides[slideIndex].components.findIndex((elem) => elem.id == payload.componentId);
    // const component = template.slides[slideIndex].components[componentIndex];
    // if (component.dataSourceId) {
    // 	template.slides[slideIndex].components
    // 		.filter((elem) => elem.dataSourceGroup == component.dataSourceGroup)
    // 		.map((elem) => template.slides[slideIndex].components.findIndex((element) => elem.id == element.id))
    // 		.forEach((index) => Vue.delete(template.slides[slideIndex].components, index));
    // }
    await Vue.delete(template.slides[slideIndex].components, componentIndex);
  },

  [types.mutations.SUCCESS_EDIT_COMPONENT](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);

    const template = state.data[templateIndex];
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    const componentIndex = template.slides[slideIndex].components.findIndex((elem) => elem.id == payload.componentId);
    const prevComponent = template.slides[slideIndex].components[componentIndex];
    const component = {
      ...prevComponent,
      ...payload.positionData,
      properties: {
        ...prevComponent.properties,
        ...payload.data,
      },
    };
    template.slides[slideIndex].components.splice(componentIndex, 1, component);
    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_MOVE_COMPONENT](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);

    const template = state.data[templateIndex];
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    const components = template.slides[slideIndex].components;
    const componentIndex = components.findIndex((elem) => elem.id == payload.componentId);
    if (payload.direction === "front") {
      components[componentIndex].layer += 1;
    } else {
      components[componentIndex].layer -= 1;
    }
    // if (payload.direction === "front") {
    //   components.push(components.splice(componentIndex, 1)[0]);
    // } else {
    //   components.unshift(components.splice(componentIndex, 1)[0]);
    // }
    Vue.set(state.data, templateIndex, template);
  },

  [types.mutations.SUCCESS_EDIT_ALL_COMPONENT](state, payload) {
    const templateIndex = state.data.findIndex((elem) => elem.id === payload.templateId);
    const template = state.data[templateIndex];
    const slideIndex = template.slides.findIndex((elem) => elem.id == payload.slideId);
    if (payload.components) {
      state.data[templateIndex].slides[slideIndex].components = payload.components;
    }
    for (let i = 0; i < state.data[templateIndex].slides[slideIndex].components.length; ++i) {
      state.data[templateIndex].slides[slideIndex].components[i].layer = i;
    }
    Vue.set(state.data, templateIndex, template);
  },

  setSelectedComponent: (state, payload) => {
    state.currentSelectedComponent = payload;
  },
  setCurrentEditMenuComponent: (state, payload) => {
    state.currentEditMenuComponent = payload;
  },
  redo(state, payload) {
    state.data = payload;
    Vue.set(state, "data", payload);
  },
};
